function BizDateUpMobileApp() {
    return (
        <section className="get-mobile-app main-class">
            <div className="row bizdate-app">
                {/* <h1 className="heading">Get the BizDateUp mobile app.</h1>
                <p className="text">Download the app today to start investing in the future or finding the backing you need to accelerate your startup’s journey.</p> */}
                <div className="buttons">
                    <button className="me-lg-3">
                        <i className="bi bi-apple me-2"></i>
                        {/* <span>Download Now</span> */}
                        <span>Coming soon</span>
                    </button>
                    <button className="mt-3 mt-lg-0">
                        <i className="bi bi-google-play me-2"></i>
                        {/* <span>Download Now</span> */}
                        <span>Coming Soon</span>
                    </button>
                </div>
                <div className="image">
                    <img src="/assets/images/app-image.svg" alt="app-image" className="img-fluid" />
                </div>
            </div>
        </section>
    )
}

export default BizDateUpMobileApp;