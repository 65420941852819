import Header from "../component/Header";
import Footer from "../component/Footer";
import BizDateUpMobileApp from "../component/BizDateUpMobileApp";
import SyndicateParallaxSlider from "../component/SyndicateParallaxSlider";

function Syndicate() {
    const whyBizDateUpList = [
        { name: 'Diversify Your Investments' },
        { name: 'Leverage Collective Knowledge' },
        { name: 'Lower Investment Thresholds' },
        { name: 'Access High-Quality Deal Flow' },
        { name: 'Reduce Risk with Shared Responsibility' },
    ];

    const syndicateJourney = [
        {
            id: '01',
            icon: '/assets/images/create-investor-account.png',
            title: 'Create Investor Account',
            description: 'Set up your profile, indicating your interests and preferred investment sectors. This helps us match you with syndicates that align with your goals.'
        },
        {
            id: '02',
            icon: '/assets/images/referral-link.png',
            title: 'Get a referral link',
            description: "Once your profile is set up, you'll receive a unique referral link. Share this link with fellow investors to expand your network and enhance your syndicate experience."
        },
        {
            id: '03',
            icon: '/assets/images/join-syndicate.png',
            title: 'Join a Syndicate',
            description: 'Once you’ve found a syndicate that interests you, join it with a few clicks. Pool your resources with other investors and begin your syndicate journey.'
        },
        {
            id: '04',
            icon: '/assets/images/grow-together.png',
            title: 'Stay Engaged and Grow Together',
            description: 'Stay updated with real-time information on your syndicate investments, attend startup events, and engage with the startup teams. Provide feedback and watch as your syndicate investment grows.'
        }
    ];

    return (
        <div className="container-fluid home-page">
            <Header />
            {/* Startup Content */}
            <section className="startup-hero margin-top-65">
                <div className="row">
                <div className="col-lg-6 d-flex">
                        <img src="/assets/images/syndicate-hero.svg" alt="investor-hero" className="img-fluid mx-auto heignt-400"  />
                    </div>
                    <div className="col-lg-6">
                        <div className="future-of-startups">
                            <h1>Unlock Collective Power with BizDateUp</h1>
                            <p className="mt-4">At BizDateUp, we believe that collaboration leads to greater impact. That’s why we offer syndicate investments, enabling investors to pool their resources and invest together in high-potential startups. Syndicate investments not only diversify your portfolio but also allow you to leverage the insights and strategies of other experienced investors, increasing the potential for returns while reducing individual risk.</p>
                            <div className="buttons">
                                <button className="start-investing-btn">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="for-investor margin-top-80">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="for-investors">
                            <div className="image">
                                <img src="/assets/images/investment-journey.png" alt="join-investor" />
                            </div>
                            <h4 className="heading mt-3">What is a Syndicate Investment?</h4>
                            <p className="text">A syndicate investment is a group of investors coming together to co-invest in a syndicate. Led by a syndicate leader, this collaborative model allows multiple investors to participate in a single deal, typically sharing knowledge, due diligence efforts, and, in some cases, co-managing the investment.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-lg-end d-flex  mt-5 mt-lg-0">
                        <img src="/assets/images/startup-hero1.svg" alt="for-investor" className="img-fluid mx-auto heignt-400" />
                    </div>
                </div>
            </section>
            <section className="why-bizdateup why-join-syndicate margin-top-80">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Why Join a Syndicate as an Investor?</h4>
                        <p>On BizDateUp, syndicate investments are designed to offer flexibility and ease. Investors can join trending syndicates in sectors they are passionate about, combining financial resources to back startups that align with their interests and goals.</p>
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            {whyBizDateUpList.map((list, index) => (
                                <li key={index}>{list.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <section className="syndicate-journey margin-top-80">
                <div className="image">
                    <img src="/assets/images/enroll.png" alt="enroll-startup" />
                </div>
                <h4 className="heading">Your Syndicate Investments  journey</h4>
                <p className="text">Discover groundbreaking startups with the potential to revolutionize industries. With our app, you can:</p>
                <div className="row justify-content-center">
                    {syndicateJourney.map((syndicate, index) => (
                        <div className="col-lg-3 col-md-6" key={syndicate.id}>
                            <div className="syndicate-item">
                                <img src={syndicate.icon} alt={syndicate.title} className="syndicate-icon" />
                                <div className="d-flex">
                                    <h6 className="syndicate-id">{syndicate.id}</h6>
                                    {index < syndicateJourney.length - 1 && <div className="step-line"></div>}
                                </div>
                                <h5 className="syndicate-title">{syndicate.title}</h5>
                                <p className="syndicate-description">{syndicate.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <SyndicateParallaxSlider />
            <section className="product-features main-class">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="enroll-startup-image">
                            <div className="image">
                                <img src="/assets/images/startup-listings.png" alt="enroll-startup" />
                            </div>
                            <h4 className="heading">Learn more about Syndicate</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 margin-top-20 mt-lg-0">
                        <div className="content">
                            <h4>Join Syndicates to Maximize Your Investments.</h4>
                            <button className="view-more-btn">Enroll Now <i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
           
            <BizDateUpMobileApp />
            <Footer />
        </div>

    );
}

export default Syndicate;
