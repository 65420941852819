import Header from "../component/Header";
import Footer from "../component/Footer";
import BizDateUpMobileApp from "../component/BizDateUpMobileApp";
import InvestorParallaxSlider from "../component/InvestorParallaxSlider";

function Investor() {
    const whyBizDateUpList = [
        { name: 'Tailored Investment Opportunities' },
        { name: 'Syndicate Investments' },
        { name: 'Provide Strategic Growth Suggestions' },
        { name: 'Access Startup Events' },
        { name: 'Real-Time Updates' },
        { name: 'Meet Startup Teams' },
    ];

    const investmentJourney = [
        {
            id: '01',
            icon: '/assets/images/planning.png',
            title: 'Planning',
            description: 'Filter startups by sector, industry, or trending opportunities that align with your investment strategy.'
        },
        {
            id: '02',
            icon: '/assets/images/investing.png',
            title: 'Investing',
            description: 'Invest directly or through syndicates with just a few clicks. Participate in trending syndicates to capitalize on popular ventures.'
        },
        {
            id: '03',
            icon: '/assets/images/guidance.png',
            title: 'Guidance & Mentorship',
            description: 'Provide your feedback and suggestions to help startups grow and succeed.'
        },
        {
            id: '04',
            icon: '/assets/images/growth.png',
            title: 'Growth & Engagement',
            description: 'Watch startups grow through regular updates, participate in their events, and track returns via your personalized dashboard.'
        }
    ];

    return (
        <div className="container-fluid home-page">
            <Header />
            {/* Investor Content */}
            <section className="investor-hero margin-top-65">
                <div className="row">
                    <div className="col-lg-6 d-flex ">
                        <img src="/assets/images/investor-hero.svg" alt="investor-hero" className="img-fluid mx-auto heignt-500" />
                    </div>
                    <div className="col-lg-6">
                        <div className="future-of-startups">
                            <h1>Invest in the Future of Innovation</h1>
                            <p className="">Join BizDateUp to invest in groundbreaking startups. Your support fuels transformative ideas, drives progress, and shapes a vibrant ecosystem where innovation thrives and meaningful change happens.</p>
                            <div className="buttons">
                                <button className="start-investing-btn">Start Investing Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="for-investor margin-top-80">
                <h4 className="text-center">Explore, Invest, and Grow.</h4>
                <div className="row mt-5 ">
                    <div className="col-lg-6">
                        <div className="for-investors">
                            <div className="image">
                                <img src="/assets/images/join-investor.png" alt="join-investor" />
                            </div>
                            <h4 className="heading mt-3">For Investors</h4>
                            <p className="text">Discover groundbreaking startups with the potential to revolutionize industries. With our app, you can:</p>
                            <div className="investor-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <p>Choose from a range of investment sectors that align with your portfolio and interests.</p>
                                </div>
                            </div>
                            <div className="investor-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <p>Collaborate with other investors to maximize your impact by joining trending investment syndicates.</p>
                                </div>
                            </div>
                            <div className="investor-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <p>Engage directly with startups by offering personalized suggestions to help them scale.</p>
                                </div>
                            </div>
                            <div className="investor-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <p>Receive real-time updates on the startups you're following, attend their events, and watch their journey unfold through videos and team introductions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex heignt-500">
                        <img src="/assets/images/for-investor.svg" alt="for-investor" className="img-fluid mx-auto" />
                    </div>
                </div>
            </section>
            <section className="investment-journey  margin-top-80">
                <div className="image">
                    <img src="/assets/images/investment-journey.png" alt="join-investor" />
                </div>
                <h4 className="heading">Your Investment Journey</h4>
                <p className="text">Discover groundbreaking startups with the potential to revolutionize industries. With our app, you can:</p>
                <div className="row mt-5 justify-content-center">
                    {investmentJourney.map((investment, index) => (
                        <div className="col-lg-3 col-md-6" key={investment.id}>
                            <div className="investment-item">
                                <img src={investment.icon} alt={investment.title} className="investment-icon" />
                                <div className="d-flex">
                                    <h6 className="investment-id">{investment.id}</h6>
                                    {index < investmentJourney.length - 1 && <div className="step-line"></div>}
                                </div>
                                <h5 className="investment-title">{investment.title}</h5>
                                <p className="investment-description">{investment.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="why-bizdateup why-bizdateup-investor  margin-top-80">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Benefits of using BizDateUp as an investor.</h4>
                        <p>At BizDateUp, we empower investors like you to discover and support innovative startups that are shaping the future. With a streamlined platform designed for ease of use, BizDateUp offers you more than just investment opportunities—it creates an immersive experience for you to engage with the startup ecosystem.</p>
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            {whyBizDateUpList.map((list, index) => (
                                <li key={index}>{list.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <InvestorParallaxSlider />
            <BizDateUpMobileApp />
            <Footer />
        </div>

    );
}

export default Investor;
