import React, { useState, useEffect, useRef } from 'react';

const SyndicateParallaxSlider = () => {
    const [scrollDirection, setScrollDirection] = useState(null);
    const [previousScrollY, setPreviousScrollY] = useState(window.pageYOffset);
    const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef(null);

    const slides = [
        {
            id: 1,
            heading: 'Unlock Collaborative Investing',
            content: 'Creating and joining syndicates allows investors to pool their resources, making it easier to invest in high-potential startups. This collaborative approach not only increases your investment capacity but also enhances the decision-making process through shared insights and experiences.',
            link: 'Learn More',
            subtitle: 'Discover new startups and much more',
            src: '/assets/images/syndicate-slide1.svg',
            backgroundColor: '#F6F7F9'
        },
        {
            id: 2,
            heading: 'Diversify Your Portfolio',
            content: 'Syndicates enable you to invest in multiple startups across different sectors with reduced individual risk. By diversifying your investments, you can spread your capital and minimize the impact of potential losses while maximizing your chances of returns.',
            link: 'Explore Diversification Opportunities',
            subtitle: 'Diversify Your Portfolio',
            src: '/assets/images/syndicate-slide2.svg',
            backgroundColor: '#F6F7F9'
        }
    ];
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.pageYOffset;

            if (currentScrollY > previousScrollY) {
                setScrollDirection('down');
            } else if (currentScrollY < previousScrollY) {
                setScrollDirection('up');
            }

            setPreviousScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScrollY]);

    useEffect(() => {
        const slideElements = document.querySelectorAll('.parallax-slide');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = Array.from(slideElements).indexOf(entry.target);
                    setActiveIndex(index);
                    if (scrollDirection === 'up') {
                        if (index !== 0) {
                            document.body.style.overflow = 'hidden';
                        } else {
                            document.body.style.overflow = 'auto';
                        }
                    } else if (scrollDirection === 'down') {
                        if (index !== slides.length - 1) {
                            document.body.style.overflow = 'hidden';
                        } else {
                            document.body.style.overflow = 'auto';
                        }
                    }
                }
            });
        }, { threshold: 0.5 });

        slideElements.forEach((slide) => observer.observe(slide));

        return () => {
            slideElements.forEach((slide) => observer.unobserve(slide));
        };
    }, [slides]);

    useEffect(() => {
        console.log(activeIndex, slides.length);
        if (scrollDirection === 'down') {
            if (activeIndex === slides.length - 1) {
                document.body.style.overflow = 'auto';
                console.log('Allow scroll', document.body.style.overflow);
            } else {
                document.body.style.overflow = 'hidden';
                console.log('Disable scroll', document.body.style.overflow);
            }
        } else if (scrollDirection === 'up') {
            if (activeIndex === 0) {
                document.body.style.overflow = 'auto';
                console.log('Allow scroll at first slide', document.body.style.overflow);
            } else {
                document.body.style.overflow = 'hidden';
                console.log('Disable scroll', document.body.style.overflow);
            }
        }
    }, [activeIndex, slides.length]);

    return (
        <div className="parallax-slider-container margin-top-80" ref={sliderRef} style={{ height: '100vh', overflowY: 'scroll' }}>
            {slides.map((slide, index) => (
                <div
                    key={slide.id}
                    className={`parallax-slide ${index === activeIndex ? 'active' : ''}`}
                    style={{
                        backgroundColor: slide.backgroundColor,
                        minHeight: '100vh',
                        transition: 'opacity 0.7s ease-in-out',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-7">
                            <div className="content">
                                <div className="image">
                                    <img
                                        src="/assets/images/parallax-slider-icon.png"
                                        alt="icon"
                                        className="parallax-icon"
                                    />
                                </div>
                                <h4 className="heading">{slide.heading}</h4>
                                <p>{slide.content}</p>
                                <p className="link">
                                    {slide.link}
                                    <i className="bi bi-arrow-right ms-3"></i>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-5 mt-lg-0">
                            <div className="content img-title d-flex flex-row align-items-center">
                                <div className="image me-3"></div>
                                <p className="subtitle">{slide.subtitle}</p>
                            </div>
                            <img src={slide.src} className="img-fluid mt-3" alt="slide" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SyndicateParallaxSlider;