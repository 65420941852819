import React, { useState } from 'react';

function FAQAccordion() {
    const accordionData = [
        {
            id: 1,
            title: 'Why BizDateUp?',
            content: "BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.",
        },
        {
            id: 2,
            title: 'How does BizDateUp work for startups?',
            content: 'BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.',
        },
        {
            id: 3,
            title: 'What is syndicate investing?',
            content: "BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.",
        },
        {
            id: 4,
            title: 'How do I join a syndicate on BizDateUp?',
            content: "BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.",
        },
        {
            id: 5,
            title: 'Can I create my own syndicate on BizDateUp?',
            content: 'BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.',
        },
        {
            id: 6,
            title: 'What sectors can I invest in?',
            content: "BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.",
        },
        {
            id: 7,
            title: 'How do I stay updated on my investments?',
            content: "BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.",
        },
        {
            id: 8,
            title: 'How does BizDateUp ensure security and transparency?',
            content: 'BizDateUp is a platform where startups and investors connect. Startups can list their businesses, raise capital, and grow, while investors can discover, invest in, and support innovative startups through individual investments or syndicate groups.',
        }
    ];

    const [openIndex, setOpenIndex] = useState(0);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="accordion" id="accordionExample">
            {accordionData.map((item, index) => (
                <div className="accordion-item mb-3" key={item.id}>
                    <h2 className="accordion-header" id={`heading${item.id}`}>
                        <button
                            className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                            type="button"
                            onClick={() => handleToggle(index)}
                            aria-expanded={openIndex === index ? 'true' : 'false'}
                            aria-controls={`collapse${item.id}`}
                        >
                            {item.title}
                            <span className="ms-auto">
                                {openIndex === index ? (
                                    <img src="/assets/images/collapse.png" />
                                ) : (
                                    <img src="/assets/images/expand.png" />
                                )}
                            </span>
                        </button>
                    </h2>
                    <div
                        id={`collapse${item.id}`}
                        className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
                        aria-labelledby={`heading${item.id}`}
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FAQAccordion;
