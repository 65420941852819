import React, { useState, useEffect, useRef } from 'react';

const StartupParallaxSlider = () => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [previousScrollY, setPreviousScrollY] = useState(window.pageYOffset);
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      id: 1,
      icon:'/assets/images/search.png',
      heading: 'Get Discovered by Investors',
      content:
        'Listing your startup on BizDateUp puts your business in front of investors who are eager to support forward-thinking companies.',
      subtitle: 'Discover new startups and much more',
      src: '/assets/images/startup-slide1.svg',
      backgroundColor: '#F6F7F9'
    },
    {
      id: 2,
      icon:'/assets/images/syndicate-investments.png',
      heading: 'Syndicate Investments for Greater Funding',
      content:
        'BizDateUp allows investors to pool their resources through syndicates, increasing your chances of securing significant funding.',
      subtitle: 'Learn more about Syndicate Investments',
      src: '/assets/images/startup-slide2.svg',
      backgroundColor: '#F6F7F9'
    },
    {
      id: 3,
      icon:'/assets/images/notification-status.png',
      heading: 'Feedback and Growth Opportunities',
      content:
        'Investors on BizDateUp don’t just offer capital; they provide valuable suggestions and feedback to help refine your product.',
      subtitle: 'Give Feedback and get Insights',
      src: '/assets/images/startup-slide3.svg',
      backgroundColor: '#F6F7F9'
    },
    {
      id: 4,
      icon:'/assets/images/people.png',
      heading: 'Host Exclusive Startup Events',
      content:
        'Engage your investors further by hosting online events, such as product demos or Q&A sessions, directly through the BizDateUp platform.',
      subtitle: 'Attend events and get to know more about the Startups',
      src: '/assets/images/startup-slide4.svg',
      backgroundColor: '#F6F7F9'
    },
  ];
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > previousScrollY) {
        setScrollDirection('down');
      } else if (currentScrollY < previousScrollY) {
        setScrollDirection('up');
      }

      setPreviousScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [previousScrollY]);

  useEffect(() => {
    const slideElements = document.querySelectorAll('.parallax-slide');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Array.from(slideElements).indexOf(entry.target);
          setActiveIndex(index);
          if (scrollDirection === 'up') {
            if (index !== 0) {
              document.body.style.overflow = 'hidden';
            } else {
              document.body.style.overflow = 'auto';
            }
          } else if (scrollDirection === 'down') {
            if (index !== slides.length - 1) {
              document.body.style.overflow = 'hidden';
            } else {
              document.body.style.overflow = 'auto';
            }
          }
        }
      });
    }, { threshold: 0.5 });

    slideElements.forEach((slide) => observer.observe(slide));

    return () => {
      slideElements.forEach((slide) => observer.unobserve(slide));
    };
  }, [slides]);

  useEffect(() => {
    console.log(activeIndex, slides.length);
    if (scrollDirection === 'down') {
      if (activeIndex === slides.length - 1) {
        document.body.style.overflow = 'auto';
        console.log('Allow scroll', document.body.style.overflow);
      } else {
        document.body.style.overflow = 'hidden';
        console.log('Disable scroll', document.body.style.overflow);
      }
    } else if (scrollDirection === 'up') {
      if (activeIndex === 0) {
        document.body.style.overflow = 'auto';
        console.log('Allow scroll at first slide', document.body.style.overflow);
      } else {
        document.body.style.overflow = 'hidden';
        console.log('Disable scroll', document.body.style.overflow);
      }
    }
  }, [activeIndex, slides.length]);

  return (
    <div className="parallax-slider-container margin-top-80" ref={sliderRef} style={{ height: '100vh', overflowY: 'scroll' }}>
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`parallax-slide ${index === activeIndex ? 'active' : ''}`}
          style={{
            backgroundColor: slide.backgroundColor,
            minHeight: '100vh',
            transition: 'opacity 0.7s ease-in-out',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-7">
              <div className="content">
                <div className="image">
                  <img src={slide.icon} alt="search" className="search-icon" />
                </div>
                <h4 className="heading">{slide.heading}</h4>
                <p>{slide.content}</p>
                <p className="link">
                  Create Your Startup Profile Today
                  <i className="bi bi-arrow-right ms-3"></i>
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="content img-title d-flex flex-row align-items-center">
                <div className="image me-3"></div>
                <p className="subtitle">{slide.subtitle}</p>
              </div>
              <img src={slide.src} className="img-fluid mt-3" alt="slide" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StartupParallaxSlider;
