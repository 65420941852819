import React from 'react';

function RecentBlogPosts() {
    return (
        <section className="blog-post-section">
            <h4 className="heading">Recent blog posts</h4>
            <div className="recent-blog-posts">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="blogs-img">
                            <img src="/assets/images/investment-healthcare.png" className="img-fluid" />
                        </div>
                        <div className="blogs-content">
                            <div>
                                <h6 className="heading">Olivia Rhye • 1 Jan 2023</h6>
                                <h6 className="subheading">Investment In Healthcare</h6>
                            </div>
                            <div>
                                <img src="/assets/images/arrow-icon.png" />
                            </div>
                        </div>
                        <p className="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                    </div>
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="blogs-right-img">
                                    <img src="/assets/images/phoenix-baker.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blogs-content">
                                    <div className="mt-3 mt-lg-0">
                                        <h6 className="heading">Phoenix Baker • 1 Jan 2023</h6>
                                        <h6 className="subheading">Advancements in Automobile</h6>
                                    </div>
                                    <div>
                                        <img src="/assets/images/arrow-icon.png" />
                                    </div>
                                </div>
                                <p className="right-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <div className="blogs-right-img">
                                    <img src="/assets/images/ev-sector-sales.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blogs-content">
                                    <div className="mt-3 mt-lg-0">
                                        <h6 className="heading">Lana Steiner • 1 Jan 2023</h6>
                                        <h6 className="subheading">EV Sector Sales</h6>
                                    </div>
                                    <div>
                                        <img src="/assets/images/arrow-icon.png" />
                                    </div>
                                </div>
                                <p className="right-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecentBlogPosts;