import React, { useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

function ContactUs() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.fullName.trim()) {
            formErrors.fullName = "Full name is required";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            formErrors.email = "Invalid email format";
            isValid = false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phoneNumber) {
            formErrors.phoneNumber = "Phone number is required";
            isValid = false;
        } else if (!phoneRegex.test(formData.phoneNumber)) {
            formErrors.phoneNumber = "Phone number should be 10 digits";
            isValid = false;
        }

        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', formData);
        }
    };

    return (
        <div className="container-fluid home-page">
            <Header />
            {/* Contact us Content */}
            <section className="hero-section">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <img src="/assets/images/contactus-hero.png" class="img-fluid mx-auto h-50"/>
                    </div>
                    <div className="col-lg-6">
                        <div className="future-of-startups">
                            <h1>Get in touch with us. We're here to assist you.</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-form">
                <div className="container">
                    <form className="contact-form-inputs" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-4 mt-5">
                                <div className="d-flex flex-column">
                                    <label htmlFor="full-name">Full Name</label>
                                    <input
                                        id="fullName"
                                        type="text"
                                        className="input-box"
                                        placeholder="Enter Full Name"
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                    />
                                    {errors.fullName && <span className="error">{errors.fullName}</span>}
                                </div>
                            </div>

                            <div className="col-lg-4 mt-5">
                                <div className="d-flex flex-column">
                                    <label htmlFor="email">Email Id</label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="input-box"
                                        placeholder="Enter Email Id"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </div>
                            </div>

                            <div className="col-lg-4 mt-5">
                                <div className="d-flex flex-column">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        id="phoneNumber"
                                        type="tel"
                                        className="input-box"
                                        placeholder="Enter Phone Number"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                    />
                                    {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                                </div>
                            </div>

                            <div className="col-12 mt-5">
                                <div className="d-flex flex-column">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        className="input-box"
                                        placeholder="Enter Message"
                                        rows="3"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                    />
                                    {errors.message && <span className="error">{errors.message}</span>}
                                </div>
                            </div>

                            <div className="col-12 my-5">
                                <button type="submit" className="message-btn">Leave us a Message<i className="bi bi-arrow-right ms-3"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <section className="contact-us-bottom">
                <div className="row">
                    <div className="col-lg-6">
                        <h6 className="subheading">Contact Info</h6>
                        <h1 className="heading">We are always happy to assist you</h1>
                    </div>
                    <div className="col-lg-3">
                        <h6 className="heading">Email Address</h6>
                        <h6>Bizdateup@info.com</h6>
                        <p>Assistance hours:<br />
                            Monday - Friday 6 am to 8 pm EST</p>
                    </div>
                    <div className="col-lg-3">
                        <h6 className="heading">Number</h6>
                        <h6>(808) 998-34256</h6>
                        <p>Assistance hours:<br />
                            Monday - Friday 6 am to 8 pm EST</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default ContactUs;
