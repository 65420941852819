import React, { useState, useEffect, useRef } from 'react';

const InvestorParallaxSlider = () => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [previousScrollY, setPreviousScrollY] = useState(window.pageYOffset);
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      id: 1,
      heading: 'Personalized Investment Experience',
      content: "Whether you're looking to join trending syndicates or invest in a specific sector, tailor your investment journey with curated recommendations and insights.",
      src: '/assets/images/investor-slide1.png',
      backgroundColor: '#F6F7F9'
    },
    {
      id: 2,
      heading: 'Control the Future of Innovation',
      content: 'Invest in emerging startups or find the right investors to fuel your growth—all in one place.',
      src: '/assets/images/investor-slide2.png',
      backgroundColor: '#F6F7F9'
    },
    {
      id: 3,
      heading: 'Innovation Meets Investment Explore a world of opportunity',
      content: "Join BizDateUp community today and build the future together.",
      src: '/assets/images/investor-slide3.png',
      backgroundColor: '#F6F7F9'
    }
  ];
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > previousScrollY) {
        setScrollDirection('down');
      } else if (currentScrollY < previousScrollY) {
        setScrollDirection('up');
      }

      setPreviousScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [previousScrollY]);

  useEffect(() => {
    const slideElements = document.querySelectorAll('.parallax-slide');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Array.from(slideElements).indexOf(entry.target);
          setActiveIndex(index);
          if (scrollDirection === 'up') {
            if (index !== 0) {
              document.body.style.overflow = 'hidden';
            } else {
              document.body.style.overflow = 'auto';
            }
          } else if (scrollDirection === 'down') {
            if (index !== slides.length - 1) {
              document.body.style.overflow = 'hidden';
            } else {
              document.body.style.overflow = 'auto';
            }
          }
        }
      });
    }, { threshold: 0.5 });

    slideElements.forEach((slide) => observer.observe(slide));

    return () => {
      slideElements.forEach((slide) => observer.unobserve(slide));
    };
  }, [slides]);

  useEffect(() => {
    console.log(activeIndex, slides.length);
    if (scrollDirection === 'down') {
      if (activeIndex === slides.length - 1) {
        document.body.style.overflow = 'auto';
        console.log('Allow scroll', document.body.style.overflow);
      } else {
        document.body.style.overflow = 'hidden';
        console.log('Disable scroll', document.body.style.overflow);
      }
    } else if (scrollDirection === 'up') {
      if (activeIndex === 0) {
        document.body.style.overflow = 'auto';
        console.log('Allow scroll at first slide', document.body.style.overflow);
      } else {
        document.body.style.overflow = 'hidden';
        console.log('Disable scroll', document.body.style.overflow);
      }
    }
  }, [activeIndex, slides.length]);

  return (
    <div className="parallax-slider-container margin-top-80" ref={sliderRef} style={{ height: '100vh', overflowY: 'scroll' }}>
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`parallax-slide ${index === activeIndex ? 'active' : ''}`}
          style={{
            backgroundColor: slide.backgroundColor,
            minHeight: '100vh',
            transition: 'opacity 0.7s ease-in-out',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="row justify-content-between align-items-center">
            {index % 2 === 0 ? (
              <>
                <div className="col-lg-7 order-2 order-lg-1">
                  <div className="content">
                    <div className="image">
                      <img
                        src="/assets/images/parallax-slider-icon.png"
                        alt="icon"
                        className="parallax-icon"
                      />
                    </div>
                    <h4 className="heading">{slide.heading}</h4>
                    <p>{slide.content}</p>
                  </div>
                </div>
                <div className="col-lg-4 order-1 order-lg-2 mt-5 mt-lg-0">
                  <img src={slide.src} className="img-fluid mt-3" alt="slide" />
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-4 order-1 order-lg-1">
                  <img src={slide.src} className="img-fluid mt-3" alt="slide" />
                </div>
                <div className="col-lg-7 order-2 order-lg-2 mt-5 mt-lg-0">
                  <div className="content">
                    <div className="image">
                      <img
                        src="/assets/images/parallax-slider-icon.png"
                        alt="icon"
                        className="parallax-icon"
                      />
                    </div>
                    <h4 className="heading">{slide.heading}</h4>
                    <p>{slide.content}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvestorParallaxSlider;