import Header from "../component/Header";
import Footer from "../component/Footer";
import BizDateUpMobileApp from "../component/BizDateUpMobileApp";

function About() {
    const whyBizDateUpList = [
        { name: 'Data Security You Can Trust' },
        { name: 'Transparency in Every Step' },
        { name: 'Designed for Ease of Use' },
        { name: 'Ease of Use for a Seamless Experience' },
        { name: 'Scalable for All Users' },
        { name: 'Efficient Communication Channels' },
        { name: 'Reliability and Support at Every Step' }
    ];
    return (
        <div className="container-fluid home-page about-page">
            <Header />
            {/* About Content */}
            <section className="hero-section">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="w-50 mx-auto d-flex flex-column align-items-center justify-content-center">
                            <img src="/assets/images/about-img.png" className="img-fluid" alt="img" />
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="future-of-startups">
                            <h1>A Team Fueled by Passion and Expertise</h1>
                            <p className="mt-4">Our team consists of entrepreneurs, investors, and tech enthusiasts who are passionate about helping startups succeed. We bring together years of experience in business development, technology, and venture capital, all united by our commitment to fostering innovation. Each member of the BizDateUp team plays a vital role in ensuring the platform operates smoothly and that every user—whether a startup founder or an investor—has the best possible experience.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-bizdateup">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Why BizDateUp</h4>
                        <p>At BizDateUp, we are more than just a platform — we are a trusted partner for innovation and growth. Whether you are a startup owner looking to connect with investors or part of a syndicate exploring the next big opportunity, BizDateUp is built to empower your journey.</p>
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            {whyBizDateUpList.map((list, index) => (
                                <li key={index}>{list.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <section className="bizdateup-content">
                <div className="row">
                    <div className="col-lg-7 content">
                        <h4 className="heading">What we do?</h4>
                        <p className="text">BizDateUp is an investment app designed to facilitate meaningful interactions between startups and investors. Our platform empowers startups by providing them with a space to list their businesses, gain visibility, and connect with potential investors who are passionate about supporting innovative ventures.</p>
                    </div>
                    <div className="col-lg-5 what-we-do-top-img px-auto d-flex d-lg-block">
                        <img src="/assets/images/what-we-do-frames.png" alt="what-we-do" className="img-fluid mx-auto" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 order-2 order-lg-1 d-flex d-lg-block">
                        <img src="/assets/images/what-we-do-frames.png" alt="gateway" className="img-fluid mx-auto" />
                    </div>
                    <div className="col-lg-7 content order-1 order-lg-2">
                        <h4 className="heading">Our Story</h4>
                        <p className="text">BizDateUp is an investment app designed to facilitate meaningful interactions between startups and investors. Our platform empowers startups by providing them with a space to list their businesses, gain visibility, and connect with potential investors who are passionate about supporting innovative ventures.</p>
                    </div>
                </div>
            </section>
            <BizDateUpMobileApp />
            <Footer />
        </div>

    );
}

export default About;
