import Header from "../component/Header";
import Footer from "../component/Footer";
import BizDateUpMobileApp from "../component/BizDateUpMobileApp";
import React from "react";
import StartupParallaxSlider from '../component/StartupParallaxSlider';

function Startup() {
    const whyBizDateUpList = [
        { name: 'Access to a Wide Network of Investors' },
        { name: 'Syndicate Investments for Faster Growth' },
        { name: 'Personalized Investor Feedback' },
        { name: 'Choose the Right Investors' },
        { name: 'Interactive Pitching Experience' },
        { name: 'Real-Time Updates and Engagement' },
    ];

    const startupJourney = [
        {
            id: '01',
            icon: '/assets/images/add-startup-detail.png',
            title: 'Add Your Startup Details',
            description: 'Start by providing the basic information about your company, including your vision, goals, and what makes your startup unique. This helps investors get a clear understanding of your business.'
        },
        {
            id: '02',
            icon: '/assets/images/winning-pitch.png',
            title: 'Create a Winning Pitch',
            description: 'Craft a compelling pitch that showcases why investors should back your startup. Make it interactive by uploading videos that highlight your product, team, and vision, helping you stand out and truly sell your business to potential investors.'
        },
        {
            id: '03',
            icon: '/assets/images/gain-visibility.png',
            title: 'Get listed and gain visibility.',
            description: 'Use the intuitive dashboard to keep an eye on all the investments made in your startup. Stay informed and manage your fundraising efforts with ease.'
        },
        {
            id: '04',
            icon: '/assets/images/investor-feedback.png',
            title: 'Engage and Grow with Investor Feedback',
            description: 'Stay active and communicate regularly with investors. Their feedback can offer valuable insights, ideas, and strategies to help boost your sales and improve your business performance.'
        }
    ];

    return (
        <div className="container-fluid home-page">
            <Header />
            {/* Startup Content */}
            <section className="startup-hero margin-top-80">
                <div className="row">
                    <div className="col-lg-6 d-flex">
                        <img src="/assets/images/startup-hero.svg" alt="startup-hero" className="img-fluid mx-auto heignt-400" />
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="future-of-startups">
                            <h1>Fuel Your Vision, Raise Capital.</h1>
                            <p className="mt-4">At BizDateUp, empower your startup with the capital it needs to grow. Connect with investors who believe in your vision and provide the support necessary to turn ideas into reality.</p>
                            <div className="buttons">
                                <button className="start-investing-btn">Enroll Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="for-investor margin-top-80">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="for-investors">
                            <div className="image">
                                <img src="/assets/images/join-investor.png" alt="join-investor" className="img-fluid" />
                            </div>
                            <h4 className="heading">Scale Your Startup with BizDateUp</h4>
                            <p className="text">At BizDateUp, we make it easy for startups to connect with the right investors and access the capital they need to grow. Our platform is designed to support entrepreneurs every step of the way, from listing your business to securing funding and scaling your operations.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-lg-end d-flex align-items-center mt-5 mt-lg-0">
                        <img src="/assets/images/scale-your-startup.svg" alt="for-investor" className="img-fluid mx-auto" />
                    </div>
                </div>
            </section>
            <section className="startup-journey margin-top-80">
                <div className="image">
                    <img src="/assets/images/enroll.png" alt="enroll-startup" />
                </div>
                <h4 className="heading">Your Startup Enrollment journey</h4>
                <div className="row justify-content-center mt-5">
                    {startupJourney.map((startup, index) => (
                        <div className="col-lg-3 col-md-6" key={startup.id}>
                            <div className="startup-item">
                                <img src={startup.icon} alt={startup.title} className="startup-icon" />
                                <div className="d-flex">
                                    <h6 className="startup-id">{startup.id}</h6>
                                    {index < startupJourney.length - 1 && <div className="step-line"></div>}
                                </div>
                                <h5 className="startup-title">{startup.title}</h5>
                                <p className="startup-description">{startup.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="why-bizdateup why-bizdateup-startup margin-top-80">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Benefits of Listing Your Startup on BizDateUp</h4>
                        <p>Unlock Growth Opportunities for Your Startup Listing your startup on BizDateUp opens doors to a world of potential investors and valuable resources that can help accelerate your business growth.</p>
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            {whyBizDateUpList.map((list, index) => (
                                <li key={index}>{list.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <StartupParallaxSlider />
            {/* <section className="learn-startups">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="learn-startup-image">
                            <div className="image">
                                <img src="/assets/images/startup-listings.png" alt="learn-startup" />
                            </div>
                            <h4 className="heading">Learn more about Startups</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="content">
                            <h4>Fuel Your Vision, Raise Capital.</h4>
                            <button className="view-more-btn">Enroll Now <i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="product-features main-class">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="enroll-startup-image">
                            <div className="image">
                                <img src="/assets/images/startup-listings.png" alt="enroll-startup" />
                            </div>
                            <h4 className="heading">Learn more about Startups</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 margin-top-20 mt-lg-0">
                        <div className="content">
                            <h4>Check our other product features</h4>
                            <button className="view-more-btn">Enroll Now <i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="platform-for-growth margin-top-80">
                <div className="row">
                    <div className="col-lg-6">
                        <h4 className="heading">BizDateUp – Your Platform for Growth</h4>
                        <p className="text">Choosing BizDateUp means choosing a platform designed to accelerate your startup’s success. With our wide network of investors, interactive tools, and collaborative features, you have everything you need to make your startup stand out, secure funding, and scale rapidly.</p>
                        <p className="subheading">Join BizDateUp today and connect with investors who believe in your vision!</p>
                    </div>
                    <div className="col-lg-6 text-lg-end">
                        <img src="/assets/images/platform-for-growth.svg" alt="for-investor" className="h-auto img-fluid" />
                    </div>
                </div>
            </section>
            <BizDateUpMobileApp />
            <Footer />
        </div>

    );
}

export default Startup;
