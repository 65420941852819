import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import RecentBlogPosts from '../component/RecentBlogPosts';
import AllBlogPosts from '../component/AllBlogPosts';

function Blogs() {
  return (
    <div className="container-fluid home-page">
      <Header />
      <RecentBlogPosts />
      <AllBlogPosts />
      <Footer />
    </div>
  )
}

export default Blogs;