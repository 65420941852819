import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./assets/css/App.css";
import Home from "./pages/home";
import Investor from './pages/investor';
import Startup from './pages/startup';
import Syndicate from './pages/syndicate';
import About from './pages/about';
import ContactUs from './pages/contactus';
import FAQ from './pages/faq';
import Blogs from './pages/blogs';
import News from './pages/news';
import BlogDetails from './pages/blogDetails';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/investor" element={<Investor />} />
        <Route path="/startup" element={<Startup />} />
        <Route path="/syndicate" element={<Syndicate />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/news" element={<News />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/blog-details/:id" element={<BlogDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;