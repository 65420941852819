import React from 'react'
import Header from '../component/Header';
import Footer from '../component/Footer';
import FAQAccordion from '../component/FAQAccordion';

function FAQ() {
    return (
        <div className="container-fluid home-page">
            <Header />
            <section className="faq-section">
                <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                        <h1 className="heading">Frequently Asked Questions</h1>
                    </div>
                </div>
                <FAQAccordion />
            </section>
            <Footer />
        </div>
    )
}

export default FAQ;