import React, { useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import blogsData from "../data/blogsData.json";
import { Link } from 'react-router-dom';

function News() {
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 6;

    const totalPages = Math.ceil(blogsData.length / blogsPerPage);

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogsData.slice(indexOfFirstBlog, indexOfLastBlog);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPageNumbers = () => {
        const pageButtons = [];
        if (totalPages <= 6) {
            for (let i = 1; i <= totalPages; i++) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        className={`btn mx-1 ${i === currentPage ? 'active' : ''}`}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            for (let i = 1; i <= 3; i++) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        className={`btn mx-1 ${i === currentPage ? 'active' : ''}`}
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage > 4) {
                pageButtons.push(
                    <span key="left-ellipsis" className="mx-1">...</span>
                );
            }

            if (currentPage > 3 && currentPage < totalPages - 2) {
                pageButtons.push(
                    <button
                        key={currentPage}
                        onClick={() => handlePageClick(currentPage)}
                        className={`btn mx-1 active`}
                    >
                        {currentPage}
                    </button>
                );
            }

            if (currentPage < totalPages - 3) {
                pageButtons.push(
                    <span key="right-ellipsis" className="mx-1">...</span>
                );
            }

            for (let i = totalPages - 2; i <= totalPages; i++) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        className={`btn mx-1 ${i === currentPage ? 'active' : ''}`}
                    >
                        {i}
                    </button>
                );
            }
        }
        return pageButtons;
    };

    return (
        <div className="container-fluid home-page">
            <Header />
            <section className="all-blogs-section">
                <h4 className="heading">News</h4>
                <div className="blog-posts">
                    <div className="row">
                        {currentBlogs.map((blog, index) => (
                            <div key={index} className="col-md-6 col-lg-4 mt-4">
                                <div className="blogs-img">
                                    <img src={blog.src} className="img-fluid" alt="blog" />
                                </div>
                                <h6 className="heading">{blog.heading}</h6>
                                <div className="blogs-content">
                                    <div>
                                        <h6 className="subheading">{blog.subheading}</h6>
                                    </div>
                                    <div>
                                        <Link to={`/blogs/blog-details/${index}`}>
                                            <img src="/assets/images/arrow-icon.png" alt="arrow icon" />
                                        </Link>
                                    </div>
                                </div>
                                <p className="content">{blog.content}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="pagination-controls mt-4">
                    <button
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                        className="btn"
                    >
                        <i className="bi bi-arrow-left me-2"></i>
                        <span className="d-none d-lg-inline-flex">Previous</span>
                    </button>

                    <div>{renderPageNumbers()}</div>

                    <button
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                        className="btn"
                    >
                        <span className="d-none d-lg-inline">Next</span>
                        <i className="bi bi-arrow-right ms-2"></i>
                    </button>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default News;