import Header from "../component/Header";
import Footer from "../component/Footer";
import BizDateUpMobileApp from "../component/BizDateUpMobileApp";

function Home() {
    const whyBizDateUpList = [
        { name: 'Data Security You Can Trust' },
        { name: 'Transparency in Every Step' },
        { name: 'Designed for Ease of Use' },
        { name: 'Ease of Use for a Seamless Experience' },
        { name: 'Scalable for All Users' },
        { name: 'Efficient Communication Channels' },
        { name: 'Reliability and Support at Every Step' }
    ];
    const features = [
        {
            id: '1',
            src: '/assets/images/startup-listings.png',
            heading: 'Startup Listing',
            content: 'Entrepreneurs can easily create profiles for their startups, highlighting their vision, goals, and unique value propositions. This allows them to attract interest from investors looking for the next big opportunity.'
        },
        {
            id: '2',
            src: '/assets/images/syndicate-investment.png',
            heading: 'Syndicate Investments',
            content: 'Entrepreneurs can easily create profiles for their startups, highlighting their vision, goals, and unique value propositions. This allows them to attract interest from investors looking for the next big opportunity.'
        },
        {
            id: '3',
            src: '/assets/images/collaborative-growth.png',
            heading: 'Collaborative Growth',
            content: 'BizDateUp promotes a culture of collaboration by allowing investors to provide constructive suggestions to startups, fostering an environment of continuous improvement and innovation.'
        },
        {
            id: '4',
            src: '/assets/images/sector-selection.png',
            heading: 'Sector Selection',
            content: 'Users can navigate through various investment sectors, enabling them to focus on areas that resonate with their interests and expertise.'
        },
        {
            id: '5',
            src: '/assets/images/engaging-events.png',
            heading: 'Engaging Events',
            content: 'Startups can host events on the platform, offering investors the chance to engage directly, ask questions, and learn more about the businesses they are interested in.'
        },
        {
            id: '6',
            src: '/assets/images/multimedia-insights.png',
            heading: 'Multimedia Insights',
            content: 'Startups can upload videos showcasing their products, team members, and company culture, allowing investors to gain deeper insights into the businesses they are considering.'
        }
    ]
    return (
        <div className="container-fluid home-page">
            <Header />
            {/* Home Content */}
            <section className="hero-section margin-top-65">
                <div className="row align-items-center">
                    <div className="col-lg-6 d-flex">
                        <img src="/assets/images/home-hero.svg" alt="investor-hero" className="img-fluid mx-auto" />
                    </div>
                    <div className="col-lg-6">
                        <div className="future-of-startups">
                            <h1>Powering the Future of Startups and Investment</h1>
                            <p className="">Welcome to BizDateUp where investors and startups connect to shape tomorrow’s success stories. Whether you're an investor seeking exciting opportunities or a startup looking for the right support, we bring everything you need to make the right connections.</p>
                            <div className="buttons">
                                <button className="start-investing-btn">Start Investing Now</button>
                                <button className="enroll-startup-btn mt-3 mt-md-0">Enroll your Startup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-bizdateup main-class">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Why BizDateUp</h4>
                        <p>At BizDateUp, we are more than just a platform — we are a trusted partner for innovation and growth. Whether you are a startup owner looking to connect with investors or part of a syndicate exploring the next big opportunity, BizDateUp is built to empower your journey.</p>
                    </div>
                    <div className="col-lg-6">
                        <ul>
                            {whyBizDateUpList.map((list, index) => (
                                <li key={index}>{list.name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <section className="bizdateup-content main-class">
                <div className="row">
                    <div className="col-lg-5 d-flex">
                        <img src="/assets/images/gateway.svg" alt="gateway" className="img-fluid mx-auto" />
                    </div>
                    <div className="col-lg-7 content">
                        <h4 className="heading">Welcome to BizDateUp – Your Gateway to Innovation and Growth</h4>
                        <p className="text">At BizDateUp, we are dedicated to transforming the way startups and investors connect and collaborate. Our platform serves as a dynamic ecosystem where visionary entrepreneurs can showcase their innovative ideas, while investors can discover and support the next generation of businesses.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7 content">
                        <h4 className="heading">What we do?</h4>
                        <p className="text">BizDateUp is an investment app designed to facilitate meaningful interactions between startups and investors. Our platform empowers startups by providing them with a space to list their businesses, gain visibility, and connect with potential investors who are passionate about supporting innovative ventures.</p>
                    </div>
                    <div className="col-lg-5 what-we-do-top-img px-auto">
                        <img src="/assets/images/what-we-do.svg" alt="what-we-do" className="img-fluid" />
                    </div>
                </div>
            </section>
            <section className="startup-investor main-class row">
            <div className="col-lg-6 ">
                        <div className="content">
                            <div className="image">
                                <img src="/assets/images/enroll.png" alt="enroll-startup" className="img-fluid" />
                            </div>
                            <h4 className="heading">Enroll Startups</h4>
                            <p className="text">
                                Step into the future with BizDateUp, where startups connect with the resources they need to scale and thrive. By joining our platform, you’ll gain access to a dynamic network of investors, seasoned mentors, and specialized tools tailored for growth. We provide an intuitive, seamless onboarding process to help you focus on what matters—building your vision. Whether you're seeking capital, strategic partnerships, or industry expertise, BizDateUp offers a robust ecosystem to fuel innovation.
                            </p>
                            <button className="primary-btn">
                                Enroll your Startup
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6  mt-lg-0 margin-top-20">
                        <div className="content">
                            <div className="image">
                                <img src="/assets/images/join-investor.png" alt="join-investor" className="img-fluid" />
                            </div>
                            <h4 className="heading">Join as an Investor</h4>
                            <p className="text">
                                BizDateUp empowers investors to discover groundbreaking startups poised for growth. With our curated selection, you’re connected to high-potential ventures across industries, backed by comprehensive insights and due diligence support. Our platform simplifies the investment process, enabling you to evaluate, invest, and engage effortlessly. Join a community committed to innovation and make impactful investments that align with your goals. At BizDateUp
                            </p>
                            <button className="primary-btn">
                                Start Investing Now
                            </button>
                        </div>
                    </div>
            </section>
            <section className="startup-investor main-class">
                <div className="row">

                <div className="col-lg-7">
                            <div className="content introducing-syndicate">
                                <h4 className="heading">Introducing  Syndicate</h4>
                                <p className="text">Lead a syndicate on BizDateUp and unite with other investors to drive high-impact ventures. Pool resources, share insights, and invest in curated startups. With transparent tools and secure communication, BizDateUp empowers you to manage collaborative investments and amplify your influence. Shape the future together.</p>
                                <button className="primary-btn">
                                    Learn More
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-5 introducing-syndicate-img px-auto  mt-lg-0">
                            <img src="/assets/images/introducing-syndicate.svg" alt="introducing-syndicate" className="img-fluid" />
                        </div>
                </div>
            </section>
            <section className=" main-class">
                <div className="benifits">
                    <h4 className="heading">How will Startups benefit</h4>
                    <div className="row">
                        <div className="col-12">
                            <div className="benifit-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <h6>Access to Capital:</h6>
                                    <p>By attracting multiple investors, startups can secure the funding they need to scale operations and bring their ideas to life.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="benifit-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <h6>Diverse Expertise:</h6>
                                    <p>Syndicates often consist of investors from various backgrounds, providing startups with a wealth of knowledge and mentorship opportunities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="benifit-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <h6>Networking Opportunities:</h6>
                                    <p>Startups gain access to a broader network of contacts, which can open doors for partnerships, customers, and further investment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benifits margin-top-65">
                    <h4 className="heading">How will Investors benefit</h4>
                    <div className="row">
                        <div className="col-12">
                            <div className="benifit-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <h6>Shared Risk:</h6>
                                    <p>By investing as part of a syndicate, investors can spread their risk across multiple startups, making it easier to support high-potential ventures without overexposing their capital.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="benifit-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <h6>Informed Decision-Making: </h6>
                                    <p>Collective intelligence allows investors to share insights and strategies, leading to more informed investment choices.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="benifit-item">
                                <div className="icon me-3">
                                    <img src="/assets/images/benifit-list-icon.png" alt="list-icon" />
                                </div>
                                <div>
                                    <h6>Engagement and Influence: </h6>
                                    <p>Investors can actively participate in the growth of their chosen startups by providing suggestions, attending events, and engaging directly with founders.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="future-item margin-top-65">
                    <h4 className="heading">Join Us in Shaping the Future</h4>
                    <p>At BizDateUp, we believe in the power of innovation and the potential of collaboration. Our mission is to create an inclusive platform where startups can thrive and investors can play a pivotal role in their journeys. Join us as we work together to shape the future of entrepreneurship and investment.</p>
                    <span>Join BizDateUp community today and build the future together.</span>
                </div>
            </section>
            <section className="product-features main-class">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="enroll-startup-image">
                            <div className="image">
                                <img src="/assets/images/startup-listings.png" alt="enroll-startup" />
                            </div>
                            <h4 className="heading">Enroll Startups</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 margin-top-20 mt-lg-0">
                        <div className="content">
                            <h4>Check our other product features</h4>
                            <button className="view-more-btn">View More <i className="bi bi-arrow-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-cards main-class">
                <div className="row">
                    {features.map((feature) => (
                        <div className="col-lg-4" key={feature.id}>
                            <div className="cards">
                                <div className="image">
                                    <img src={feature.src} alt="feature-img" />
                                </div>
                                <h5>{feature.heading}</h5>
                                <p>{feature.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="startup-investor what-we-do margin-top-65">
                <div className="row align-items-center">
                    <div className="col-lg-5 what-we-do-img px-auto">
                        <img src="/assets/images/what-we-do-frame.png" alt="what-we-do" className="img-fluid" />
                    </div>
                    <div className="col-lg-7">
                        <div className="content">
                            <h4 className="heading">What we do?</h4>
                            <p className="text">BizDateUp bridges startups and investors, fostering growth through strategic connections, mentorship, and funding. We empower startups to scale and investors to discover innovative ventures, creating a vibrant ecosystem where opportunities thrive and impactful investments drive the future of entrepreneurship. Together, we shape what’s next.</p>
                        </div>
                    </div>
                </div>
            </section>
            <BizDateUpMobileApp />
            <Footer />
        </div>

    );
}

export default Home;
