import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const location = useLocation();
    return (
        <div>
            <div className="footer margin-top-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="footer-logo">
                                <img src="/assets/images/BizDateUp-footer-icon.svg" alt="footer_icon" />
                            </div>
                            <div className="footer-description">
                                Make your complicated finance more simple.
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <ul className="list">
                                <li className={`link ${location.pathname === '/' ? 'active' : ''}`}>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className={`link ${location.pathname === '/investor' ? 'active' : ''}`}>
                                    <Link to="/investor">Investor</Link>
                                </li>
                                <li className={`link ${location.pathname === '/startup' ? 'active' : ''}`}>
                                    <Link to="/startup">Startup</Link>
                                </li>
                                <li className={`link ${location.pathname === '/syndicate' ? 'active' : ''}`}>
                                    <Link to="/syndicate">Syndicate</Link>
                                </li>
                                <li className="link">
                                    <Link to="/">Contact Us</Link>
                                </li>
                                <li className="link">
                                    <Link to="/">Legal</Link>
                                    <ul className="sub-list">
                                        <li>
                                            Privacy Policy
                                        </li>
                                        <li>
                                            Term of Service
                                        </li>
                                        <li>
                                            Cookies
                                        </li>
                                    </ul>
                                </li>
                                <li className="link">
                                    <Link to="/">Resources</Link>
                                    <ul className="sub-list">
                                        <li className="link">
                                            <Link to="/about">About us</Link>
                                        </li>
                                        <li className="link">
                                            <Link to="/faq">FAQ's</Link>
                                        </li>
                                        <li className="link">
                                            <Link to="/faq">News</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                © 2024 BizDateUp All right reserved.
            </div>
        </div>
    )
}

export default Footer;