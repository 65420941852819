import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    const resourceLink = [
        {
            id: '01',
            src: '/assets/images/about-us-link.png',
            heading: 'About Us',
            subheading: 'Get creative inspiration',
            link: '/about'
        },
        {
            id: '02',
            src: '/assets/images/news-link.png',
            heading: 'News',
            subheading: 'Impactful storytelling',
            link: '/news'
        },
        {
            id: '03',
            src: '/assets/images/faq-link.png',
            heading: "FAQ's",
            subheading: 'Stunning web design',
            link: '/faq'
        },
        {
            id: '04',
            src: '/assets/images/blogs-link.png',
            heading: 'Blogs',
            subheading: 'Functional digital products',
            link: '/blogs'
        },
    ]
    return (
        <nav className="navbar navbar-expand-xl navbar-light bg-light">
            <div className="navbar-responsive">
                <a className="navbar-brand" href="#">
                    <img src="/assets/images/BizDateUp-logo.svg" alt="header_logo" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <img src="/assets/images/nav-menu-icon.png" alt="icon" className="h-45 w-75" />
                </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/investor' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/investor">Investor</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/startup' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/startup">Startup</Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/syndicate' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/syndicate">Syndicate</Link>
                    </li>
                    <li className={`nav-item dropdown ${location.pathname === '/about' || location.pathname === '/faq' || location.pathname === '/news' || location.pathname === '/blogs' || /^\/blogs\/blog-details\/\d+$/.test(location.pathname) ? 'active' : ''}`}>
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Resources
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <div className="row">
                                {resourceLink.map((resource) => (
                                    <div className="col-lg-6 my-1" key={resource.id}>
                                        <div className="d-flex">
                                            <div className="col-2">
                                                <img src={resource.src} alt="link" className="img-fluid" />
                                            </div>
                                            <div className="col-8">
                                                <h5>
                                                    {resource.heading}
                                                </h5>
                                                <p>{resource.subheading}</p>
                                            </div>
                                            <div className="col-2">
                                                <Link to={resource.link}>
                                                    <img src="/assets/images/arrow-icon.png" className="icon" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </li>
                    <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/contact">Contact Us</Link>
                    </li>
                    <li className="nav-item active d-block d-xl-none">
                        <Link className="nav-link login" to="/">Login</Link>
                    </li>
                    <li className="nav-item d-block d-xl-none">
                        <button className="nav-link nav-button">Signup</button>
                    </li>
                </ul>
            </div>
            <div className="d-none d-xl-block">
                <ul className="navbar-right-nav">
                    <li className="nav-item active">
                        <Link className="nav-link login" to="/">Login</Link>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link nav-button">Signup</button>
                    </li>
                    <li className="ms-xl-4">
                        <img src="/assets/images/nav-menu-icon.png" alt="icon" className="h-75 w-75" />
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Header;